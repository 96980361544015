
import { Component, Prop, Vue } from 'vue-property-decorator';
import metaDataModule from '@/store/modules/metaDataModule';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';
import { getView, sleep } from '@/utils/helpers';
import TasqJob from '@/interfaces/tasqs/TasqJob';

@Component({
  components: {
    FormFieldPreview: () => getView('FormFieldPreview'),
  },
})
export default class TasqWellActionFeedback extends Vue {
  @Prop({ required: true }) tasq!: TasqJob;

  actionItems: any = [];

  activeTab: any = '';

  sortActionsInAscendingOrder = false;

  hidePreviewFieldSection = false;

  selectedActionInputTabLocal = '';

  formDetailsLoading = false;

  searchQuery = '';

  selectedActions: any = [];

  isLoadingDetails = false;

  get selectedActionInputTab() {
    return tasqFeedbackModule.selectedActionInputTab;
  }



  async created() {
    await this.setupActionDetails();
  }

  async setupActionDetails(notUpdateTab = false) {
    const aiFields = tasqFeedbackModule.selectedFormsFields;
    this.isLoadingDetails = true;
    const localFormsList: any = [];
    const selectedForms = tasqFeedbackModule.selectedForms;
    let completedRequests = 0;
    selectedForms.forEach(async (form, i) => {
      try {
        const localFormDetail: any = {};
        Object.assign(localFormDetail, form);
        localFormDetail.dateOfDisplay = localFormDetail.dateOfDisplay;

        if (localFormDetail && localFormDetail.fields) {
          localFormDetail.fields = localFormDetail.fields.map((field) => {
            const aiField = aiFields.find((f) => f.id == field.id);
            const fieldReturnObj = field;
            if (aiField) {
              fieldReturnObj.localVal = aiField.aiSuggestion;
              if(fieldReturnObj.category === 'Date/Time'){
                fieldReturnObj.localVal = new Date()
              }
            }else if(localFormDetail.downtime){
              if(fieldReturnObj.category === 'Date/Time'){
                fieldReturnObj.localVal = new Date(fieldReturnObj.localVal)


              }else if(fieldReturnObj['format'] === 'Number' && fieldReturnObj['type'] === 'single'){
                fieldReturnObj.localVal = parseInt(fieldReturnObj.localVal)
              }

            } else {
              fieldReturnObj.localVal = '';
            }


            return fieldReturnObj;
          });
        } else {
          localFormDetail.fields = [];
        }

        localFormDetail.stringID = `${localFormDetail.id}`;
        localFormDetail.isCompleteForToday = false;
        localFormsList.push(localFormDetail);
        completedRequests++;
      } catch (error) {

        console.log(error);
      } finally {
        if (completedRequests === selectedForms.length) {
          const sortedSelectedFormsList = localFormsList.sort((a, b) =>
            a.title.localeCompare(b.title)
          );
          this.selectedActions = sortedSelectedFormsList;
          tasqFeedbackModule.setSelectedActionDetails(this.selectedActions[0]);
          if(!this.selectedActionInputTab){
            tasqFeedbackModule.setSelectedActionInputTab(
            this.selectedActions[0]?.id
          );
          this.selectedActionInputTabLocal = `${this.selectedActions[0]?.id}`;
          }
          this.selectedActionInputTabLocal = `${this.selectedActionInputTab}`;
          this.isLoadingDetails = false;
        }
      }
    });
  }

  refreshForm() {
    this.formDetailsLoading = true;
    this.$nextTick(() => {
      this.formDetailsLoading = false;

      this.$forceUpdate();
    });
  }

  updateFieldValue(payload) {
    const formIndex = this.selectedActions.findIndex(
      (form) => form.id === payload.formId
    );
    if (formIndex > -1) {
      const fieldIndex = this.selectedActions[formIndex].fields.findIndex(
        (field) => field.id === payload.fieldId
      );
      if (fieldIndex > -1) {
        const formField = JSON.parse(
          JSON.stringify(this.selectedActions[formIndex].fields[fieldIndex])
        );
        if (formField && formField.category === 'Dropdown') {
          this.hidePreviewFieldSection = true;
        }
        formField.localVal = payload.value;
        this.selectedActions[formIndex].fields[fieldIndex] = formField;
      }
    }
    this.$nextTick(() => {
      this.hidePreviewFieldSection = false;
    });
  }

  validateFields(fields) {
    const errors: any = [];
    fields.forEach((field) => {
      const errorExist = this.$refs[field.id]![0].validateField();
      if (errorExist) {
        errors.push(errorExist);
      }
    });

    return errors;
  }

  async submitInput() {
    const actionDetails = this.selectedActions.find(
      (input) => input.id === this.selectedActionInputTab
    );
    if (actionDetails && actionDetails.fields && actionDetails.fields.length) {
      this.hidePreviewFieldSection = true;
      this.$nextTick(() => {
        this.hidePreviewFieldSection = false;
      });

      await sleep(100);

      const errors = this.validateFields(actionDetails.fields);
      console.error(errors);
      if (errors && errors.length) {
        return false;
      }
    }

    const currentFormIndex = this.selectedActions.findIndex(
      (form) => form.id === this.selectedActionInputTab
    );

    this.selectedActions[currentFormIndex].validateForToday = true;

    const nextForm = this.selectedActions.find(
      (form) => form.validateForToday === false
    );

    if (nextForm) {
      tasqFeedbackModule.setSelectedActionDetails(nextForm);
      tasqFeedbackModule.setSelectedActionInputTab(nextForm.id);
      this.selectedActionInputTabLocal = this.selectedActionInputTab;
      return false;
    }
    tasqFeedbackModule.setSeletedForms(this.selectedActions);
    return true;
  }
}
